import React, {useCallback, useEffect, useState} from "react";
import Loading from "../../Utility/Loading/Loading";
import {Api} from "../../Api/Api";
import Notice from "../../Utility/Notice/Notice";
import moment from "moment";
import FormElementSelect from "../../Form/Element/Select";
import FormElementText from "../../Form/Element/Text";
import DateOfBirthPicker from "../../Utility/DateOfBirthPicker/DateOfBirthPicker";
import Button from "../../Form/Element/Button";
import EmailVerification from "./EmailVerification";
import './StudentSignup.scss';
import FormBarSelect from "../../Form/Element/FormBarSelect";

const StudentSignup = ({user_id, email}) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [error, setError] = useState(null);
    const [saveErrors, setSaveErrors] = useState([]);
    const [saving, setSaving] = useState(false);
    const [firstLoad,setFirstLoad] = useState(true);
    const [bar, setBar] = useState({});
    const updateUserData = useCallback(() => {
        setSaving(true);
        Api.get('/customers/student/student-status', {
            id: user_id,
            email: email,
            first_load: firstLoad
        }).then((response) => {
            setSaving(false);
            if (response.ok === false) {
                setError(response.error);
                setLoading(false);
                setTimeout(() => updateUserData(), 10000);
                return;
            }
            setError(null);
            setUser(response.data.data);
            setFirstLoad(false);
            if (response.data.data.has_student_offers === false && response.data.data.student_verified === true) {
                setTimeout(() => updateUserData(), 10000);
            }
            setLoading(false);
        })
    }, [user_id, email]);

    const updateBarData = () => {
        Api.get('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json').then((response) => {
            console.log(response);
        })
    }

    useEffect(() => {
        updateUserData();
        updateBarData();
    }, []);

    const handleSubmit = useCallback(() => {
        const updateData = {
            app_user_id: user_id,
            email: email
        };
        setError(null);
        setSaveErrors([]);
        const validationErrors = [];
        user.fields_required.forEach((field) => {
            if (!user[field]) {
                if (field === 'birth_date') {
                    validationErrors.push('Date of birth is required');
                } else if (field === 'graduation_year') {
                    validationErrors.push('Your year of graduation is required');
                } else if (field === 'post_code') {
                    validationErrors.push('Your term time postcode is required');
                } else {
                    validationErrors.push(field + ' is required');
                }
            }
            updateData[field] = user[field];
        });
        if (validationErrors.length === 0) {
            setSaving(true);
            Api.post('customers/student/student-status', updateData).then((response) => {
                setSaving(false);
                if (response.ok) {
                    updateUserData();
                } else {
                    setSaveErrors([response.error])
                }
            });
            return;
        }

        setSaveErrors(validationErrors);
    }, [user]);

    useEffect(() => {
        setUser({...user, selected_location_id: bar ? parseInt(bar.cost_centre) : null});
    }, [bar]);
    useEffect(() => {
        setTimeout(() => {
            document.title = 'Halls of Rev-idence';
        }, 100);
    }, []);

    const handleFieldChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    };
    const handleDobChange = (dob) => {
        if (dob) {
            setUser({...user, birth_date: moment(dob, 'YYYY/M/D').format('YYYY-MM-DD')});
        }
    }
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Notice name="error" variant="error">{error}</Notice>
    }

    if (user.has_student_offers === false && user.student_verified === true) {
        return <div style={{margin: '20px'}}>
            <Notice name="offers" variant="info">Hmmm.. You are all signed up but don't appear to have the offers yet.
                Give it 5 minutes and we'll chuck more hamsters on the wheel.</Notice>
        </div>
    }
    if (user.fields_required.length > 0) {
        return <div className='student-signup'>
            <h1>WELCOME TO YOUR HAPPY PLACE</h1>
            <p>New here? Welcome to Your Happy Place, the home of your essential student offers, deals and
                discounts.</p>
            <p>We need some quick deets so we can be sure we’re always showing you the best offers. Just thank us later
                at the bar.</p>
            {saveErrors.length > 0 ? <Notice name="error">
                {saveErrors.map((error) => {
                    return <p key={error}>{error}</p>
                })}
            </Notice> : null}

            <div className="student-signup__field-group">
                {user.fields_required.map((field) => {
                    if (field === 'graduation_year') {
                        const years = [
                            moment().add(1, 'year').format('Y'),
                            moment().add(2, 'year').format('Y'),
                            moment().add(3, 'year').format('Y'),
                            moment().add(4, 'year').format('Y'),
                        ];
                        return <FormElementSelect name="graduation_year" selectedValue={user.graduation_year ?? ''}
                                                  key={field}
                                                  includeBlankOption={true}
                                                  blankOptionText=" - Year Of Graduation -"
                                                  values={years.map((year) => {
                                                      return {key: year, value: year}
                                                  })}
                                                  onChange={(e) => handleFieldChange(e)} placeholder="Graduation Year"/>
                    }

                if (field === 'uni_email') {
                    return <FormElementText name="uni_email" value={user.uni_email ?? ''}
                                            email={true}
                                            key={field}
                                            onChange={(e) => handleFieldChange(e)} placeholder="Uni e-mail address"/>
                }
                if (field === 'post_code') {
                    return <FormElementText name="post_code" value={user.post_code ?? ''}
                                            email={true}
                                            key={field}
                                            onChange={(e) => handleFieldChange(e)} placeholder="Term Time Postcode"/>
                }
                    if (field === 'selected_location_id') {
                        return <FormBarSelect onChange={(e) => setBar(e.target.value)} name="bar"
                                              value={bar.id ?? ''}
                                              returnType="object"
                                              blankOptionText=" - Term Time Bar -" brand="revolution"/>
                    }
                if (field === 'birth_date') {
                    return <DateOfBirthPicker name='birth_date' value={user.birth_date}
                                              key={field}
                                              handleChange={(e) => handleDobChange(e)} inputTypePills/>
                }
            })}
            </div>
            <div style={{marginTop: '10px'}}/>
            <Button text="Save My Details" name="update" variant="full-width" onClick={() => handleSubmit()}
                    disabled={saving}/>
        </div>
    }

    if (user.uni_email === null) {
        return <EmailVerification user={user} updateUser={() => updateUserData()} student={true}/>
    }
    if (user.personal_email === null) {
        return <EmailVerification user={user} updateUser={() => updateUserData()} student={false}/>
    }

    if (user.has_student_offers === true) {
        return <div>
            <Notice name="success" variant="info">All done! You’re all set up with our amazing student offers. Now all that’s left to do is to head to the offers section in the app and get the party started. </Notice>
        </div>
    }

    return <div>
        <Notice name="error">You really should not have got to this point.</Notice>
    </div>
}

export default StudentSignup;
