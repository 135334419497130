import React, {Component} from 'react';
import {Api} from "../Api/Api";
import PropTypes from 'prop-types';
import FormElementCheckbox from "../Form/Element/Checkbox";
import Loading from "../Utility/Loading/Loading";
import _ from 'lodash';
import './AllergenEdit.scss';
import IntroText from "../Utility/IntroText/IntroText";

class AllergenEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            allergens: []
        }
    }

    componentDidMount() {
        this.updateAllergens();
    }

    updateAllergens() {
        Api.get('rest/bars/bar-menus/allergens').then((response) => {
            if (response.ok) {
                this.setState({loading: false, allergens: response.data.data});
                return;
            }

            this.setState({loading: false});
        });
    }

    handleAllergenChange(id) {
        let allergens = _.extend([], this.props.allergens);
        let index = allergens.indexOf(id);
        if (index === -1) {
            allergens.push(id);
        } else {
            allergens.splice(index, 1);
        }
        this.props.handleAllergenChange(allergens);
    }

    render() {
        if (this.state.loading === true) {
            return <Loading/>
        }
        let vegan_id = null;
        let vegetarian_id = null;
        return <div className="allergen-list">
            <IntroText name="allergies">Allergies</IntroText>
            {this.state.allergens.map((allergen) => {
                if (allergen.name === 'Vegan') {
                    vegan_id = '' + allergen.id;
                    return null;
                }
                if (allergen.name === 'Vegetarian') {
                    vegetarian_id = '' + allergen.id;
                    return null;
                }
                return <FormElementCheckbox onChange={() => this.handleAllergenChange(allergen.id)}
                                            name={'allergen_' + allergen.id}
                                            key={allergen.id}
                                            label={allergen.name} label_after={true}
                                            value={this.props.allergens.indexOf(allergen.id) > -1}/>
            })}

            {vegan_id || vegetarian_id ?
                <IntroText name="other-preferences">Other Dietary Preferences</IntroText> : null}
            {vegan_id ? <FormElementCheckbox onChange={() => this.handleAllergenChange(vegan_id)}
                                             name={'allergen_' + vegan_id}
                                             label="Vegan" label_after={true}
                                             value={this.props.allergens.indexOf(vegan_id) > -1}/> : null}
            {vegetarian_id ? <FormElementCheckbox onChange={() => this.handleAllergenChange(vegetarian_id)}
                                                  name={'allergen_' + vegetarian_id}
                                                  label="Vegan" label_after={true}
                                                  value={this.props.allergens.indexOf(vegetarian_id) > -1}/> : null}
        </div>
    }
}

AllergenEdit.propTypes = {
    allergens: PropTypes.array.isRequired,
    handleAllergenChange: PropTypes.func.isRequired
}

export default AllergenEdit;